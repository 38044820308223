body {
    background-color: #1a1a1a;
    margin: 0;
    padding-top: 2em;
    padding-bottom: 3em;
}

h1, h2, a, p {
    font-family: Arial, sans-serif;   
}

p {
    line-height: 1.4;
}

a:hover {
    opacity: .5;
}

h1, h2, p {
    cursor: default;
}

.clickable {
    cursor: pointer
}

.clickable:hover {
    opacity: .5;
}
